export const brandName = "EAK Wire";
export const countryList = {
  Afghanistan: "Afghanistan",
  AlandIslands: "Aland Islands",
  Albania: "Albania",
  Algeria: "Algeria",
  AmericanSamoa: "American Samoa",
  Andorra: "Andorra",
  Angola: "Angola",
  Anguilla: "Anguilla",
  Antarctica: "Antarctica",
  AntiguaAndBarbuda: "Antigua And Barbuda",
  Argentina: "Argentina",
  Armenia: "Armenia",
  Aruba: "Aruba",
  Australia: "Australia",
  Austria: "Austria",
  Azerbaijan: "Azerbaijan",
  Bahamas: "Bahamas",
  Bahrain: "Bahrain",
  Bangladesh: "Bangladesh",
  Barbados: "Barbados",
  Belarus: "Belarus",
  Belgium: "Belgium",
  Belize: "Belize",
  Benin: "Benin",
  Bermuda: "Bermuda",
  Bhutan: "Bhutan",
  Bolivia: "Bolivia",
  BonaireSintEustatiusSaba: "Bonaire Sint Eustatius Saba",
  BosniaAndHerzegovina: "Bosnia And Herzegovina",
  Botswana: "Botswana",
  BouvetIsland: "Bouvet Island",
  Brazil: "Brazil",
  BritishIndianOceanTerritory: "British Indian Ocean Territory",
  BruneiDarussalam: "Brunei Darussalam",
  Bulgaria: "Bulgaria",
  BurkinaFaso: "Burkina Faso",
  Burundi: "Burundi",
  Cambodia: "Cambodia",
  Cameroon: "Cameroon",
  Canada: "Canada",
  CapeVerde: "CapeVerde",
  CaymanIslands: "Cayman Islands",
  CentralAfricanRepublic: "Central African Republic",
  Chad: "Chad",
  Chile: "Chile",
  China: "China",
  ChristmasIsland: "Christmas Island",
  CocosKeelingIslands: "CocosKeeling Islands",
  Colombia: "Colombia",
  Comoros: "Comoros",
  Congo: "Congo",
  CongoDemocraticRepublic: "CongoDemocratic Republic",
  CookIslands: "Cook Islands",
  CostaRica: "Costa Rica",
  CoteDIvoire: "Cote DIvoire",
  Croatia: "Croatia",
  Cuba: "Cuba",
  Curacao: "Curacao",
  Cyprus: "Cyprus",
  CzechRepublic: "Czech Republic",
  Denmark: "Denmark",
  Djibouti: "Djibouti",
  Dominica: "Dominica",
  DominicanRepublic: "Dominican Republic",
  Ecuador: "Ecuador",
  Egypt: "Egypt",
  ElSalvador: "ElS alvador",
  EquatorialGuinea: "Equatorial Guinea",
  Eritrea: "Eritrea",
  Estonia: "Estonia",
  Ethiopia: "Ethiopia",
  FalklandIslands: "Falkland Islands",
  FaroeIslands: "Faroe Islands",
  Fiji: "Fiji",
  Finland: "Finland",
  France: "France",
  FrenchGuiana: "French Guiana",
  FrenchPolynesia: "French Polynesia",
  FrenchSouthernTerritories: "French Southern Territories",
  Gabon: "Gabon",
  Gambia: "Gambia",
  Georgia: "Georgia",
  Germany: "Germany",
  Ghana: "Ghana",
  Gibraltar: "Gibraltar",
  Greece: "Greece",
  Greenland: "Greenland",
  Grenada: "Grenada",
  Guadeloupe: "Guadeloupe",
  Guam: "Guam",
  Guatemala: "Guatemala",
  Guernsey: "Guernsey",
  Guinea: "Guinea",
  GuineaBissau: "Guinea Bissau",
  Guyana: "Guyana",
  Haiti: "Haiti",
  HeardIslandMcdonaldIslands: "Heard Island Mcdonald Islands",
  HolySeeVaticanCityState: "Holy See Vatican City State",
  Honduras: "Honduras",
  HongKong: "Hong Kong",
  Hungary: "Hungary",
  Iceland: "Iceland",
  India: "India",
  Indonesia: "Indonesia",
  Iran: "Iran",
  Iraq: "Iraq",
  Ireland: "Ireland",
  IsleOfMan: "Isle Of Man",
  Israel: "Israel",
  Italy: "Italy",
  Jamaica: "Jamaica",
  Japan: "Japan",
  Jersey: "Jersey",
  Jordan: "Jordan",
  Kazakhstan: "Kazakhstan",
  Kenya: "Kenya",
  Kiribati: "Kiribati",
  Korea: "Korea",
  KoreaDemocraticPeoplesRepublic: "Korea Democratic Peoples Republic",
  Kuwait: "Kuwait",
  Kyrgyzstan: "Kyrgyzstan",
  LaoPeoplesDemocraticRepublic: "La oPeoples Democratic Republic",
  Latvia: "Latvia",
  Lebanon: "Lebanon",
  Lesotho: "Lesotho",
  Liberia: "Liberia",
  LibyanArabJamahiriya: "Libyan Arab Jamahiriya",
  Liechtenstein: "Liechtenstein",
  Lithuania: "Lithuania",
  Luxembourg: "Luxembourg",
  Macao: "Macao",
  Macedonia: "Macedonia",
  Madagascar: "Madagascar",
  Malawi: "Malawi",
  Malaysia: "Malaysia",
  Maldives: "Maldives",
  Mali: "Mali",
  Malta: "Malta",
  MarshallIslands: "Marshall Islands",
  Martinique: "Martinique",
  Mauritania: "Mauritania",
  Mauritius: "Mauritius",
  Mayotte: "Mayotte",
  Mexico: "Mexico",
  Micronesia: "Micronesia",
  Moldova: "Moldova",
  Monaco: "Monaco",
  Mongolia: "Mongolia",
  Montenegro: "Montenegro",
  Montserrat: "Montserrat",
  Morocco: "Morocco",
  Mozambique: "Mozambique",
  Myanmar: "Myanmar",
  Namibia: "Namibia",
  Nauru: "Nauru",
  Nepal: "Nepal",
  Netherlands: "Netherlands",
  NewCaledonia: "NewCaledonia",
  NewZealand: "NewZealand",
  Nicaragua: "Nicaragua",
  Niger: "Niger",
  Nigeria: "Nigeria",
  Niue: "Niue",
  NorfolkIsland: "Norfolk Island",
  NorthernMarianaIslands: "Northern Mariana Islands",
  Norway: "Norway",
  Oman: "Oman",
  Pakistan: "Pakistan",
  Palau: "Palau",
  PalestinianTerritory: "Palestinian Territory",
  Panama: "Panama",
  PapuaNewGuinea: "Papua New Guinea",
  Paraguay: "Paraguay",
  Peru: "Peru",
  Philippines: "Philippines",
  Pitcairn: "Pitcairn",
  Poland: "Poland",
  Portugal: "Portugal",
  PuertoRico: "PuertoRico",
  Qatar: "Qatar",
  Reunion: "Reunion",
  Romania: "Romania",
  RussianFederation: "Russian Federation",
  Rwanda: "Rwanda",
  SaintBarthelemy: "Saint Barthelemy",
  SaintHelena: "Saint Helena",
  SaintKittsAndNevis: "Saint Kitts And Nevis",
  SaintLucia: "SaintLucia",
  SaintMartin: "SaintMartin",
  SaintPierreAndMiquelon: "Saint Pierre AndMiquelon",
  SaintVincentAndGrenadines: "Saint Vincent And Grenadines",
  Samoa: "Samoa",
  SanMarino: "SanMarino",
  SaoTomeAndPrincipe: "Sao Tome And Principe",
  SaudiArabia: "Saudi Arabia",
  Senegal: "Senegal",
  Serbia: "Serbia",
  Seychelles: "Seychelles",
  SierraLeone: "SierraLeone",
  Singapore: "Singapore",
  SintMaarten: "Sint Maarten",
  Slovakia: "Slovakia",
  Slovenia: "Slovenia",
  SolomonIslands: "Solomon Islands",
  Somalia: "Somalia",
  SouthAfrica: "South Africa",
  SouthGeorgiaAndSandwichIsl: "South Georgia And SandwichIsl",
  SouthSudan: "South Sudan",
  Spain: "Spain",
  SriLanka: "SriLanka",
  Sudan: "Sudan",
  Suriname: "Suriname",
  SvalbardAndJanMayen: "Svalbard And JanMayen",
  Swaziland: "Swaziland",
  Sweden: "Sweden",
  Switzerland: "Switzerland",
  SyrianArabRepublic: "Syrian Arab Republic",
  Taiwan: "Taiwan",
  Tajikistan: "Tajikistan",
  Tanzania: "Tanzania",
  Thailand: "Thailand",
  TimorLeste: "TimorLeste",
  Togo: "Togo",
  Tokelau: "Tokelau",
  Tonga: "Tonga",
  TrinidadAndTobago: "Trinidad And Tobago",
  Tunisia: "Tunisia",
  Turkey: "Turkey",
  Turkmenistan: "Turkmenistan",
  TurksAndCaicosIslands: "Turks And Caicos Islands",
  Tuvalu: "Tuvalu",
  Uganda: "Uganda",
  Ukraine: "Ukraine",
  UnitedArabEmirates: "UAE",
  UnitedKingdom: "United Kingdom",
  UnitedStates: "United States",
  UnitedStatesOutlyingIslands: "United States Outlying Islands",
  Uruguay: "Uruguay",
  Uzbekistan: "Uzbekistan",
  Vanuatu: "Vanuatu",
  Venezuela: "Venezuela",
  Vietnam: "Vietnam",
  VirginIslandsBritish: "Virgin Islands British",
  VirginIslandsUS: "Virgin Islands US",
  WallisAndFutuna: "Wallis And Futuna",
  WesternSahara: "Western Sahara",
  Yemen: "Yemen",
  Zambia: "Zambia",
  Zimbabwe: "Zimbabwe",
};
export const TermsAndConditionsList = [
  "Credits are non-refundable.",
  `Credits can be used across all ${brandName} features without restrictions.`,
  "Purchases are secure, with all major credit cards and cryptocurrencies accepted.",
  "Any changes to credit packages will be communicated in advance.",
  "Credits cannot be transferred between EAK accounts.",
  "Bulk credit purchases may qualify for discounts or bonus credits.",
  "Support is available for any credit purchase inquiries or issues at hello@eakwire.com",
];
export const MAX_IMAGE_SIZE = 10 * 1024 * 1024;
export const TimelineOptions = [
  { label: "Within One Day", value: "D1" },
  { label: "Within Two Days", value: "D2" },
  { label: "Within Five Days", value: "D5" },
  { label: "Within One Week", value: "W1" },
  { label: "Within Two Weeks", value: "W2" },
];
export const LinkTypeOptions = [
  { label: "Do Follow", value: "DO_FOLLOW" },
  { label: "No Follow", value: "NO_FOLLOW" },
];
export const CategoryOptions = [
  { label: "General", value: "GENERAL" },
  { label: "Awards", value: "AWARDS" },
  { label: "Organic", value: "ORGANIC" },
  { label: "Press Release", value: "PRESS_RELEASE" },
  { label: "Sponsored", value: "SPONSORED" },
  { label: "Company Name Change", value: "COMAPNY_NAME_CHANGE" },
  { label: "Company Name Update", value: "COMAPNY_NAME_UPDATE" },
  { label: "Conference Call", value: "CONFERENCE_CALL" },
  { label: "Conferences", value: "CONFERENCES" },
  { label: "Diversity", value: "DIVERSITY" },
  { label: "Equity", value: "EQUITY" },
  { label: "Dividend Earning", value: "DIVIDEND_EARNING" },
  { label: "Environmental", value: "ENVIRONMENTAL" },
  { label: "Social", value: "SOCIAL" },
  { label: "Governance Exchange Listing", value: "GOVERNANCE_EXCHANGE_LISTING" },
  { label: "IPO", value: "IPO" },
  { label: "Lawsuits", value: "LAWSUITS" },
  { label: "Management Changes", value: "MANAGEMENT_CHANGES" },
  { label: "Merger", value: "MERGER" },
  { label: "Acquisition", value: "ACQUISITION" },
  { label: "Partnership", value: "PARTNERSHIP" },
  { label: "Product Announcement", value: "PRODUCT_ANNOUNCEMENT" },
  { label: "Regulatory", value: "REGULATORY" },
  { label: "Shareholder Meeting", value: "SHAREHOLDER_MEETING" },
];

export const BundleCategoryOptions = [
  { label: "Web3", value: "WEB3" },
  { label: "Yahoo", value: "YAHOO" },
  { label: "Wire", value: "WIRE" },
  { label: "Regional", value: "REGIONAL" },
  { label: "Region", value: "REGION" },
  { label: "Language", value: "LANGUAGE" },
  { label: "Mainstream", value: "MAINSTREAM" },
  { label: "Gaming", value: "GAMING" },
  { label: "NFT", value: "NFT" },
  { label: "Premier", value: "PREMIER" },
];
export const BundleTypeOptions = [
  { label: "Standard", value: "STANDARD" },
  { label: "Premium", value: "PREMIUM" },
  { label: "Gold", value: "GOLD" },
  { label: "Diamond", value: "DIAMOND" },
  { label: "Whitelist", value: "WHITELIST" },
  { label: "GMI", value: "GMI" },
  { label: "WAGMI", value: "WAGMI" },
  { label: "Bull Run", value: "BULL_RUN" },
  { label: "Media Degen", value: "MEDIA_DEGEN" },
  { label: "Chinese", value: "CHINESE" },
  { label: "Japanese", value: "JAPANESE" },
  { label: "Korean", value: "KOREAN" },
  { label: "Turkish", value: "TURKISH" },
  { label: "Vietnamese", value: "VIETNAMESE" },
  { label: "French", value: "FRENCH" },
  { label: "Dutch", value: "DUTCH" },
  { label: "German", value: "GERMAN" },
  { label: "Indonesian", value: "INDONESIAN" },
  { label: "Portuguese", value: "PORTUGUESE" },
  { label: "Spanish", value: "SPANISH" },
];
export const StatusOptions = [
  { label: "Scheduled", value: "SCHEDULED" },
  { label: "Awaiting Approval", value: "AWAITING_APPROVAL" },
  { label: "Published", value: "PUBLISHED" },
  { label: "Rejected", value: "REJECTED" },
];

export const visibilityOptions = [
  {
    value: "SUPPRESSED",
    label: "Suppressed",
    background: "#F9565C",
  },
  {
    value: "PUBLISHED",
    label: "Published",
    background: "#47C94C",
  },
  {
    value: "PRIVATE",
    label: "Private",
    background: "#FCD404",
  },
];
export const statusOptions = [
  {
    value: "DRAFT",
    label: "Draft",
    background: `#000000`,
  },
  {
    value: "SUBMITTED",
    label: "Submitted",
    background: "#01B7F3",
  },
  {
    value: "REVIEW",
    label: "Review",
    background: "#747474",
  },
  {
    value: "AWAITING_APPROVAL",
    label: "Awaiting Approval",
    background: "#439DEE",
  },
  {
    value: "SCHEDULED",
    label: "Scheduled",
    background: "#FF9900",
  },
  {
    value: "REJECTED",
    label: "Rejected",
    background: "#FF4D53",
  },
  {
    value: "PUBLISHED",
    label: "Published",
    background: "#47BC4C",
  },
];

export const Timezones = [
  { label: "(GMT-11:00) Pago Pago", value: "Pacific/Pago_Pago" },
  { label: "(GMT-10:00) Hawaii Time", value: "Pacific/Honolulu" },
  { label: "(GMT-08:00) Pacific Time", value: "America/Los_Angeles" },
  { label: "(GMT-08:00) Pacific Time - Tijuana", value: "America/Tijuana" },
  { label: "(GMT-07:00) Mountain Time", value: "America/Denver" },
  { label: "(GMT-07:00) Mountain Time - Arizona", value: "America/Phoenix" },
  { label: "(GMT-07:00) Mountain Time - Chihuahua, Mazatlan", value: "America/Mazatlan" },
  { label: "(GMT-06:00) Central Time", value: "America/Chicago" },
  { label: "(GMT-06:00) Central Time - Mexico City", value: "America/Mexico_City" },
  { label: "(GMT-06:00) Central Time - Regina", value: "America/Regina" },
  { label: "(GMT-06:00) Guatemala", value: "America/Guatemala" },
  { label: "(GMT-05:00) Bogota", value: "America/Bogota" },
  { label: "(GMT-05:00) Eastern Time", value: "America/New_York" },
  { label: "(GMT-05:00) Lima", value: "America/Lima" },
  { label: "(GMT-04:30) Caracas", value: "America/Caracas" },
  { label: "(GMT-04:00) Atlantic Time - Halifax", value: "America/Halifax" },
  { label: "(GMT-04:00) Guyana", value: "America/Guyana" },
  { label: "(GMT-04:00) La Paz", value: "America/La_Paz" },
  { label: "(GMT-03:00) Buenos Aires", value: "America/Argentina/Buenos_Aires" },
  { label: "(GMT-03:00) Godthab", value: "America/Godthab" },
  { label: "(GMT-03:00) Montevideo", value: "America/Montevideo" },
  { label: "(GMT-03:30) Newfoundland Time - St. Johns", value: "America/St_Johns" },
  { label: "(GMT-03:00) Santiago", value: "America/Santiago" },
  { label: "(GMT-02:00) Sao Paulo", value: "America/Sao_Paulo" },
  { label: "(GMT-02:00) South Georgia", value: "Atlantic/South_Georgia" },
  { label: "(GMT-01:00) Azores", value: "Atlantic/Azores" },
  { label: "(GMT-01:00) Cape Verde", value: "Atlantic/Cape_Verde" },
  { label: "(GMT+00:00) Casablanca", value: "Africa/Casablanca" },
  { label: "(GMT+00:00) Dublin", value: "Europe/Dublin" },
  { label: "(GMT+00:00) Lisbon", value: "Europe/Lisbon" },
  { label: "(GMT+00:00) London", value: "Europe/London" },
  { label: "(GMT+00:00) Monrovia", value: "Africa/Monrovia" },
  { label: "(GMT+01:00) Algiers", value: "Africa/Algiers" },
  { label: "(GMT+01:00) Amsterdam", value: "Europe/Amsterdam" },
  { label: "(GMT+01:00) Berlin", value: "Europe/Berlin" },
  { label: "(GMT+01:00) Brussels", value: "Europe/Brussels" },
  { label: "(GMT+01:00) Budapest", value: "Europe/Budapest" },
  { label: "(GMT+01:00) Central European Time - Belgrade", value: "Europe/Belgrade" },
  { label: "(GMT+01:00) Central European Time - Prague", value: "Europe/Prague" },
  { label: "(GMT+01:00) Copenhagen", value: "Europe/Copenhagen" },
  { label: "(GMT+01:00) Madrid", value: "Europe/Madrid" },
  { label: "(GMT+01:00) Paris", value: "Europe/Paris" },
  { label: "(GMT+01:00) Rome", value: "Europe/Rome" },
  { label: "(GMT+01:00) Stockholm", value: "Europe/Stockholm" },
  { label: "(GMT+01:00) Vienna", value: "Europe/Vienna" },
  { label: "(GMT+01:00) Warsaw", value: "Europe/Warsaw" },
  { label: "(GMT+02:00) Athens", value: "Europe/Athens" },
  { label: "(GMT+02:00) Bucharest", value: "Europe/Bucharest" },
  { label: "(GMT+02:00) Cairo", value: "Africa/Cairo" },
  { label: "(GMT+02:00) Jerusalem", value: "Asia/Jerusalem" },
  { label: "(GMT+02:00) Johannesburg", value: "Africa/Johannesburg" },
  { label: "(GMT+02:00) Helsinki", value: "Europe/Helsinki" },
  { label: "(GMT+02:00) Kiev", value: "Europe/Kiev" },
  { label: "(GMT+02:00) Moscow-01 - Kaliningrad", value: "Europe/Kaliningrad" },
  { label: "(GMT+02:00) Riga", value: "Europe/Riga" },
  { label: "(GMT+02:00) Sofia", value: "Europe/Sofia" },
  { label: "(GMT+02:00) Tallinn", value: "Europe/Tallinn" },
  { label: "(GMT+02:00) Vilnius", value: "Europe/Vilnius" },
  { label: "(GMT+03:00) Istanbul", value: "Europe/Istanbul" },
  { label: "(GMT+03:00) Baghdad", value: "Asia/Baghdad" },
  { label: "(GMT+03:00) Nairobi", value: "Africa/Nairobi" },
  { label: "(GMT+03:00) Minsk", value: "Europe/Minsk" },
  { label: "(GMT+03:00) Riyadh", value: "Asia/Riyadh" },
  { label: "(GMT+03:00) Moscow+00 - Moscow", value: "Europe/Moscow" },
  { label: "(GMT+03:30) Tehran", value: "Asia/Tehran" },
  { label: "(GMT+04:00) Baku", value: "Asia/Baku" },
  { label: "(GMT+04:00) Moscow+01 - Samara", value: "Europe/Samara" },
  { label: "(GMT+04:00) Tbilisi", value: "Asia/Tbilisi" },
  { label: "(GMT+04:00) Yerevan", value: "Asia/Yerevan" },
  { label: "(GMT+04:30) Kabul", value: "Asia/Kabul" },
  { label: "(GMT+05:00) Karachi", value: "Asia/Karachi" },
  { label: "(GMT+05:00) Moscow+02 - Yekaterinburg", value: "Asia/Yekaterinburg" },
  { label: "(GMT+05:00) Tashkent", value: "Asia/Tashkent" },
  { label: "(GMT+05:30) Colombo", value: "Asia/Colombo" },
  { label: "(GMT+06:00) Almaty", value: "Asia/Almaty" },
  { label: "(GMT+06:00) Dhaka", value: "Asia/Dhaka" },
  { label: "(GMT+06:30) Rangoon", value: "Asia/Rangoon" },
  { label: "(GMT+07:00) Bangkok", value: "Asia/Bangkok" },
  { label: "(GMT+07:00) Jakarta", value: "Asia/Jakarta" },
  { label: "(GMT+07:00) Moscow+04 - Krasnoyarsk", value: "Asia/Krasnoyarsk" },
  { label: "(GMT+08:00) China Time - Beijing", value: "Asia/Shanghai" },
  { label: "(GMT+08:00) Hong Kong", value: "Asia/Hong_Kong" },
  { label: "(GMT+08:00) Kuala Lumpur", value: "Asia/Kuala_Lumpur" },
  { label: "(GMT+08:00) Moscow+05 - Irkutsk", value: "Asia/Irkutsk" },
  { label: "(GMT+08:00) Singapore", value: "Asia/Singapore" },
  { label: "(GMT+08:00) Taipei", value: "Asia/Taipei" },
  { label: "(GMT+08:00) Ulaanbaatar", value: "Asia/Ulaanbaatar" },
  { label: "(GMT+08:00) Western Time - Perth", value: "Australia/Perth" },
  { label: "(GMT+09:00) Moscow+06 - Yakutsk", value: "Asia/Yakutsk" },
  { label: "(GMT+09:00) Seoul", value: "Asia/Seoul" },
  { label: "(GMT+09:00) Tokyo", value: "Asia/Tokyo" },
  { label: "(GMT+09:30) Central Time - Darwin", value: "Australia/Darwin" },
  { label: "(GMT+10:00) Eastern Time - Brisbane", value: "Australia/Brisbane" },
  { label: "(GMT+10:00) Guam", value: "Pacific/Guam" },
  { label: "(GMT+10:00) Moscow+07 - Magadan", value: "Asia/Magadan" },
  { label: "(GMT+10:00) Moscow+07 - Yuzhno-Sakhalinsk", value: "Asia/Vladivostok" },
  { label: "(GMT+10:00) Port Moresby", value: "Pacific/Port_Moresby" },
  { label: "(GMT+10:30) Central Time - Adelaide", value: "Australia/Adelaide" },
  { label: "(GMT+11:00) Eastern Time - Hobart", value: "Australia/Hobart" },
  { label: "(GMT+11:00) Eastern Time - Melbourne, Sydney", value: "Australia/Sydney" },
  { label: "(GMT+11:00) Guadalcanal", value: "Pacific/Guadalcanal" },
  { label: "(GMT+11:00) Noumea", value: "Pacific/Noumea" },
  { label: "(GMT+12:00) Majuro", value: "Pacific/Majuro" },
  { label: "(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy", value: "Asia/Kamchatka" },
  { label: "(GMT+13:00) Auckland", value: "Pacific/Auckland" },
  { label: "(GMT+13:00) Fakaofo", value: "Pacific/Fakaofo" },
  { label: "(GMT+13:00) Fiji", value: "Pacific/Fiji" },
  { label: "(GMT+13:00) Tongatapu", value: "Pacific/Tongatapu" },
  { label: "(GMT+14:00) Apia", value: "Pacific/Apia" },
];
